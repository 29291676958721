import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.jsx";
// import RtlLayout from "layouts/RTL.jsx";
import AdminLayout from "layouts/Admin.jsx";
import UserLayout from "layouts/User.jsx";
import appReducer from "./store/reducers";
//import initialState from "./store/initialState.json";
import { createStore, applyMiddleware } from "redux";
import initialState from "./store/initialState.json";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import Cookies from "universal-cookie";
import "assets/scss/material-dashboard-pro-react.scss?v=1.5.0";
import C from "./store/constants";
//var initialState = {};
const store = applyMiddleware(thunk)(createStore)(appReducer, initialState);
//const hist = createBrowserHistory();
const hist = createBrowserHistory({
  basename: "/app"
});
//in json  "homepage": "http://35.190.159.138/development/build/",
window.store = store;
const cookies = new Cookies();
if (cookies.get("apiKey") !== undefined) {
  store.dispatch({
    type: C.SET_API_KEY,
    payload: cookies.get("apiKey")
  });
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        {/* <Route path="/rtl" component={RtlLayout} /> */}
        <Route path="/auth" component={AuthLayout} />
        <Route path="/admin" component={AdminLayout} />
        <Route path="/" component={UserLayout} />
        <Redirect from="/" to="/dashboard" />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
