import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addCategory, updateCategory } from "store/Actions.js";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
//import Email from "@material-ui/icons/Email";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

class AddCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      file: null
    };
    this.getImageFile = this.getImageFile.bind(this);
    this.updateCategory = this.updateCategory.bind(this);
    this.addCategory = this.addCategory.bind(this);
  }
  componentDidMount() {
    if (this.props.update === true) {
      this.setState({
        name: this.props.category.name,
        description: this.props.category.description
      });
    }
  }
  sendState() {
    return this.state;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }
  change(event, stateName) {
    this.setState({ [stateName]: event.target.value });
  }
  getImageFile(file) {
    if (file != null) {
      this.setState({
        file: file
      });
    }
  }

  handleSaveCategory() {
    if (this.props.update === true) {
      this.updateCategory();
    } else {
      this.addCategory();
    }
    this.props.closeModal();
  }

  addCategory() {
    this.props.addCategory(
      this.state.name,
      this.props.parent_id,
      this.state.description,
      this.state.file
    );
  }
  updateCategory() {
    this.props.updateCategory(
      this.props.category.id,
      this.state.name,
      this.props.parent_id,
      this.state.description,
      this.state.file
    );
  }
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        {/* <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText} />
        </GridItem> */}
        <GridItem xs={12} sm={8}>
          {this.props.update ? (
            <PictureUpload
              getImageFile={this.getImageFile}
              update={this.props.update}
              image_path={this.props.category.image_path}
            />
          ) : (
            <PictureUpload getImageFile={this.getImageFile} />
          )}
        </GridItem>
        <GridItem xs={12} sm={8}>
          <CustomInput
            // success={this.state.firstnameState === "success"}
            // error={this.state.firstnameState === "error"}
            labelText={<span>Name</span>}
            id="firstname"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "name"),
              defaultValue: this.props.update ? this.props.category.name : "",
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            // success={this.state.lastnameState === "success"}
            // error={this.state.lastnameState === "error"}
            labelText={<span>Description</span>}
            id="lastname"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "description"),
              defaultValue: this.props.update
                ? this.props.category.description
                : "",
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <RecordVoiceOver className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={7} sm={7} />
        <GridItem xs={2} sm={2}>
          <Button color="rose" onClick={() => this.handleSaveCategory()}>
            Save
          </Button>
        </GridItem>
      </GridContainer>
    );
  }
}

AddCategory.propTypes = {
  parent_id: PropTypes.number,
  addCategory: PropTypes.func,
  updateCategory: PropTypes.func,
  closeModal: PropTypes.func,
  classes: PropTypes.object,
  update: PropTypes.bool,
  category: PropTypes.object
};

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = () => dispatch => {
  return {
    addCategory(categoryName, parent_id, description, file) {
      dispatch(addCategory(categoryName, parent_id, description, file));
    },
    updateCategory(id, categoryName, parent_id, description, file) {
      dispatch(updateCategory(id, categoryName, parent_id, description, file));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(AddCategory));

//export default withStyles(style)(AddCategory);
