import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
//import Icon from "@material-ui/core/Icon";
import { getInitData, setHeader, BASE_URL } from "store/Actions.js";
//import InfoOutline from "@material-ui/icons/InfoOutline";
import Add from "@material-ui/icons/Add";
import Button from "components/CustomButtons/Button.jsx";
import BackArrow from "@material-ui/icons/ArrowBackIos";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
const style = {
  ...dashboardStyle,
  headerText: {
    textAlign: "center"
  }
};
class CategoryDashboard extends React.Component {
  state = {
    value: 0
  };
  componentDidMount() {
    this.props.getInitData();
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  getLink = (categoryID, categoryName) => {
    var isParent = false;
    this.props.allCategories.map(cat => {
      if (cat.parent_id === categoryID) {
        isParent = true;
      }
      return isParent;
    });
    this.props.setHeader(categoryName);
    if (isParent) {
      this.props.history.push("/subcategory/" + categoryID);
      return "/subcategory/" + categoryID;
    } else {
      this.props.history.push("/category/" + categoryID);
      return "/category/" + categoryID;
    }
  };
  getParent = categoryID => {
    var isParent = false;
    this.props.allCategories.map(cat => {
      if (cat.parent_id === categoryID) {
        isParent = true;
      }
      return isParent;
    });
    return isParent;
  };
  render() {
    const { classes, categories, parent_id } = this.props;

    return (
      <div>
        {parent_id > 0 ? (
          <Button
            color="success"
            onClick={() => this.props.history.push("/dashboard")}
          >
            <BackArrow /> Back
          </Button>
        ) : null}
        <GridContainer>
          <GridItem xs={12}>
            <h1 className={classes.headerText}>
              Welcome To The Low Content Publishing Explorer.
            </h1>
          </GridItem>
          <GridItem xs={12}>
            <h3>
              The Low Content Publishing Explorer is your top resource for
              finding low content tools, training, templates, graphics and more,
              for all of your publishing needs.
            </h3>
          </GridItem>
          <GridItem xs={12}>
            <h3>
              To get started, simply select your category below, and you will be
              directed to the best resources available.
            </h3>
          </GridItem>
          {categories.map((category, index) => {
            return (
              <GridItem xs={12} sm={6} md={6} lg={3} key={index}>
                <Link
                  to={
                    this.getParent(category.id)
                      ? "/subcategory/" + category.id
                      : "/category/" + category.id
                  }
                >
                  <Card raised>
                    <CardHeader color="warning" stats icon>
                      <CardIcon color="warning">
                        {category.image_path === "" ? (
                          <Add />
                        ) : (
                          <img
                            style={{ maxWidth: "48px", maxHeight: "48px" }}
                            width={"1120px"}
                            height="90px"
                            className={classes.cardImgTop}
                            src={BASE_URL + "/" + category.image_path}
                            alt="..."
                          />
                        )}
                      </CardIcon>
                      {/* <p className={classes.cardCategory}> {category.name} </p> */}
                      <h3 className={classes.cardTitleCategory}>
                        {category.name}
                      </h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        {/* <Danger>
                        <Warning />
                      </Danger>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        Get more space
                      </a> */}
                      </div>
                    </CardFooter>
                  </Card>
                </Link>
              </GridItem>
            );
          })}
        </GridContainer>
      </div>
    );
  }
}

CategoryDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  categories: PropTypes.array,
  allCategories: PropTypes.array,
  getInitData: PropTypes.func,
  setHeader: PropTypes.func
};

const mapStateToProps = (state, props) => {
  var id = props.match.params.id;
  if (id === undefined) {
    id = 0;
  }
  var categories = state.category.filter(c => c.parent_id === parseInt(id, 10));
  return {
    categories: categories,
    allCategories: state.category,
    parent_id: parseInt(id, 10)
  };
};

const mapDispatchToProps = () => disptach => {
  return {
    getInitData() {
      disptach(getInitData());
    },
    setHeader(headerName) {
      disptach(setHeader(headerName));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(CategoryDashboard));
