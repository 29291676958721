import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
//import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Button from "components/CustomButtons/Button.jsx";
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
//import AddCategory from "custom_components/AddCategory.jsx";
import AddCategory from "custom_components/AddCategory.jsx";
//import AddAlert from "@material-ui/icons/AddAlert";
import Close from "@material-ui/icons/Close";
function Transition(props) {
  return <Slide direction="down" {...props} />;
}
class CategoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noticeModal: true
    };
  }

  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }
  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }
  render() {
    const { classes, modalOpen } = this.props;
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          // classes={{
          //   root: classes.center + " " + classes.modalRoot,
          //   paper: classes.modal
          // }}
          open={modalOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("noticeModal")}
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
        >
          <DialogTitle
            id="notice-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => this.props.closeModal()}
            >
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>Add Category</h4>
          </DialogTitle>
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
            <AddCategory
              closeModal={this.props.closeModal}
              parent_id={this.props.parent_id}
              update={this.props.update}
              category={this.props.category}
            />
          </DialogContent>
          {/* <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button
              onClick={() => this.props.closeModal("noticeModal")}
              color="info"
              round
            >
              Save
            </Button>
          </DialogActions> */}
        </Dialog>
      </div>
    );
  }
}

CategoryModal.propTypes = {
  classes: PropTypes.object,
  modalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  parent_id: PropTypes.number,
  update: PropTypes.bool,
  category: PropTypes.object
};

export default withStyles(notificationsStyle)(CategoryModal);
