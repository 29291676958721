import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { login, setApikey } from "store/Actions.js";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
//import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import LoginAlert from "./LoginAlert.jsx";
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      alert: null,
      show: false,
      cardAnimaton: "cardHidden",
      username: "",
      password: ""
    };
    this.loginFailedMessage = this.loginFailedMessage.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  change(event, type) {
    this.setState({ [type]: event.target.value });
  }
  handleLogin = () => {
    login(this.state.username, this.state.password)
      .then(response => {
        if (response.status === 200) {
          this.props.setApikey(response.data.apiKey);
        }
      })
      .catch(error => {
        this.loginFailedMessage("Wrong username or password");
      });
  };

  loginFailedMessage(message) {
    this.setState({
      alert: <LoginAlert message={message} hideAlert={this.hideAlert} />
    });
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }
  render() {
    const { classes } = this.props;
    if (this.props.apiKey !== null) {
      return <Redirect to="/admin" />;
    }
    return (
      <div className={classes.container}>
        {this.state.alert}
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="rose"
                >
                  <h4 className={classes.cardTitle}>Log in</h4>
                  <div className={classes.socialLine}>
                    {[
                      "fab fa-facebook-square",
                      "fab fa-twitter",
                      "fab fa-google-plus"
                    ].map((prop, key) => {
                      return (
                        <Button
                          color="transparent"
                          justIcon
                          key={key}
                          className={classes.customButtonClass}
                        >
                          <i className={prop} />
                        </Button>
                      );
                    })}
                  </div>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="Email..."
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.username,
                      onChange: event => this.change(event, "username"),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: this.state.password,
                      type: "password",
                      onChange: event => this.change(event, "password"),
                      onKeyPress: event => {
                        if (event.key === "Enter") this.handleLogin();
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      )
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button
                    color="rose"
                    simple
                    size="lg"
                    block
                    onClick={() => this.handleLogin()}
                  >
                    Let's Go
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  apiKey: PropTypes.string,
  setApikey: PropTypes.func
};

const mapStateToProps = state => {
  return {
    categories: state.category,
    apiKey: state.apiKey
  };
};

const mapDispatchToProps = () => dispatch => {
  return {
    setApikey(apiKey) {
      dispatch(setApikey(apiKey));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(loginPageStyle)(LoginPage));
//export default withStyles(loginPageStyle)(LoginPage);
