import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
//import defaultImage from "assets/img/920x100.png";
import cardImagesStyles from "assets/jss/material-dashboard-pro-react/cardImagesStyles.jsx";

const styles = {
  ...cardImagesStyles,
  textWhite: {
    "&, & *": {
      color: "#FFF"
    }
  }
};

class CardsImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: this.props.ad.url,
      file: null,
      ad: this.props.ad,
      imagePreviewUrl:
        "https://rebrandablecontent.com/api/" + this.props.ad.path
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    //this.handleRemove = this.handleRemove.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.ad !== this.state.ad) {
      this.setState({
        imagePreviewUrl:
          "https://rebrandablecontent.com/api/" + nextProps.ad.path,
        url: nextProps.ad.url
      });
    }
  }
  change(event, type) {
    this.setState({ [type]: event.target.value });
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
  }
  render() {
    const { classes } = this.props;
    return (
      <div className="fileinput">
        <input type="file" onChange={this.handleImageChange} ref="fileInput" />
        <Card width="1120px">
          <img
            style={{ maxWidth: "1120px", maxHeight: "90" }}
            width={"1120px"}
            height="90px"
            className={classes.cardImgTop}
            src={this.state.imagePreviewUrl}
            alt="..."
          />
          <CardBody>
            <CustomInput
              labelText="Url"
              id="url"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "url",
                value: this.state.url,
                onChange: event => this.change(event, "url")
              }}
            />
            <Button color="primary" onClick={() => this.handleClick()}>
              Select Image
            </Button>
            <Button
              color="primary"
              onClick={() =>
                this.props.handleAdSave(this.state.url, this.state.file)
              }
            >
              Save
            </Button>
          </CardBody>
        </Card>
        <br />
      </div>
    );
  }
}

CardsImages.propTypes = {
  classes: PropTypes.object.isRequired,
  ad: PropTypes.object,
  handleAdSave: PropTypes.func
};
export default withStyles(styles)(CardsImages);
