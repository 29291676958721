import AdminCategory from "views/Dashboard/AdminCategory.jsx";
import AdsDashboard from "views/Dashboard/Ads.jsx";
import AdminResourceTable from "views/Tables/AdminResourceTable.jsx";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AdIcon from "@material-ui/icons/AccountBalance";
var dashRoutes = [
  {
    secure: true,
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: AdminCategory,
    layout: "/admin"
  },
  {
    secure: true,
    path: "/ads",
    name: "Ads",
    rtlName: "إعلانات",
    icon: AdIcon,
    component: AdsDashboard,
    layout: "/admin"
  },
  {
    secure: true,
    hidden: true,
    path: "/category/:id",
    name: "Resource",
    icon: DashboardIcon,
    component: AdminResourceTable,
    layout: "/admin"
  },
  {
    secure: true,
    hidden: true,
    path: "/subcategory/:id",
    name: "Resource",
    icon: DashboardIcon,
    component: AdminCategory,
    layout: "/admin"
  },
  {
    redirect: true,
    path: "/admin",
    pathTo: "/admin/dashboard",
    name: "Dashboard"
  }
];
export default dashRoutes;
