const constants = {
  SET_HEADER: "SET_HEADER",
  CLEAR_HEADER: "CLEAR_HEADER",
  ADD_AD: "ADD_AD",
  REMOVE_AD: "REMOVE_AD",
  SET_ADS: "SET_ADS",
  ADD_CATEGORY: "ADD_CATEGORY",
  SET_CATEGORY: "SET_CATEGORY",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  REMOVE_CATEGORY: "REMOVE_CATEGORY",
  ADD_DOMAIN_RESOURCE: "ADD_DOMAIN_RESOURCE",
  SET_DOMAIN_RESOURCE: "SET_DOMAIN_RESOURCE",
  MERGE_DOMAIN_RESOURCE: "MERGE_DOMAIN_RESOURCE",
  REMOVE_DOMAIN_RESOURCE: "REMOVE_DOMAIN_RESOURCE",
  USER_LOGOUT: "USER_LOGOUT",
  SET_API_KEY: "SET_API_KEY",
  CLEAR_API_KEY: "CLEAR_API_KEY",
  SET_SMTP: "SET_SMTP",
  REMOVE_SMTP: "SET_SMTP",
  ADD_TEMP_LEAD: "ADD_TEMP_LEAD",
  CLEAR_TEMP_LEADS: "CLEAR_TEMP_LEADS",
  REMOVE_TEMP_LEAD: "ADD_TEMP_LEAD",
  SET_LEADS: "SET_LEADS",
  ADD_LEAD: "ADD_LEAD",
  REMOVE_LEAD: "REMOVE_LEAD",
  CLEAR_LEADS: "CLEAR_LEADS",
  ADD_CAMPAIGN: "ADD_CAMPAIGN",
  REMOVE_CAMPAIGN: "REMOVE_CAMPAIGN",
  SET_CAMPAIGN: "SET_CAMPAIGN",
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  ADD_ERROR: "ADD_ERROR",
  CLEAR_ERROR: "CLEAR_ERROR",
  FETCH_LEADS: "FETCH_LEADS",
  CANCEL_FETCHING: "CANCEL_FETCHING",
  CHANGE_SUGGESTIONS: "CHANGE_SUGGESTIONS",
  CLEAR_SUGGESTIONS: "CLEAR_SUGGESTIONS"
};

export default constants;
