import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Add from "@material-ui/icons/Add";
import { BASE_URL } from "store/Actions.js";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
//import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Back from "@material-ui/icons/ArrowBackIos";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  description: {
    ...cardTitle,
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  icon: {
    width: 64,
    height: 64,
    color: "black",
    cursor: "pointer"
  },
  cardLink: {
    color: "black",
    "&:hover": {
      color: "black"
    }
  }
};

class ResourceTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getData() {
    return this.props.resources.map((prop, key) => {
      return {
        id: key,
        name: prop.name,
        description: prop.description,
        url: (
          <div>
            <a
              href={prop.url}
              target="_blank"
              rel="noopener noreferrer"
              className={this.props.classes.cardLink}
            >
              {prop.url}
            </a>
          </div>
        ),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}
            {/* use this button to add a edit kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                window.open(prop.url, "_blank");
              }}
              color="warning"
              className="edit"
            >
              <Dvr />
            </Button>{" "}
          </div>
        )
      };
    });
  }
  render() {
    const { classes, category } = this.props;
    return (
      <GridContainer>
        <GridItem>
          <Button color="primary" onClick={() => this.props.history.goBack()}>
            <Back />
            Back
          </Button>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                {category.image_path === "" ? (
                  <Add />
                ) : (
                  <img
                    style={{ maxWidth: "48px", maxHeight: "48px" }}
                    width={"1120px"}
                    height="90px"
                    className={classes.cardImgTop}
                    src={BASE_URL + "/" + category.image_path}
                    alt="..."
                  />
                )}
              </CardIcon>
              <h3 className={classes.cardIconTitle}>{category.name}</h3>
              <h5 className={classes.description}>{category.description}</h5>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={this.getData()}
                //filterable
                columns={[
                  {
                    Header: "Name",
                    accessor: "name"
                  },
                  {
                    Header: "URL",
                    accessor: "url"
                  },
                  {
                    Header: "Description",
                    accessor: "description"
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={100}
                showPaginationBottom
                minRows={1}
                //showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

ResourceTable.propTypes = {
  classes: PropTypes.object.isRequired,
  resources: PropTypes.array,
  history: PropTypes.object,
  category: PropTypes.object
};
const mapStateToProps = (state, props) => {
  var id = props.match.params.id;
  var resources = state.domain_resourses.filter(
    r => r.category_id === parseInt(id, 10)
  );
  var categories = state.category.filter(c => c.id === parseInt(id, 10));
  var category = {};
  if (categories.length > 0) {
    category = categories[0];
  }
  return {
    resources: resources,
    category: category
  };
};

const mapDispatchToProps = () => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ResourceTable));
//export default withStyles(styles)(ReactTables);
