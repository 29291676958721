import C from "./constants";
import axios from "axios";
import Cookies from "universal-cookie";

export const BASE_URL = "https://rebrandablecontent.com/api";
//"http://localhost/api";
// axios.interceptors.request.use(function(config) {
//   config.headers.Authorization = "Bearer 123456789";
//   return config;
// });

const getCategories = () => {
  return axios.get(BASE_URL + "/category");
};
const getResources = () => {
  return axios.get(BASE_URL + "/resources");
};
const getAds = () => {
  return axios.get(BASE_URL + "/ads");
};

export const addError = error => {
  return {
    type: C.ADD_ERROR,
    payload: error
  };
};
export const getInitData = () => dispatch => {
  getCategories()
    .then(response => {
      var categories = response.data;
      dispatch({
        type: C.SET_CATEGORY,
        payload: categories
      });
    })
    .catch(error => {
      dispatch(addError(error.message));
      //console.log(error.message);
    });
  getResources().then(response => {
    dispatch({
      type: C.SET_DOMAIN_RESOURCE,
      payload: response.data
    });
  });
  getAds().then(response => {
    dispatch({
      type: C.SET_ADS,
      payload: response.data
    });
  });
};
export const setHeader = headerName => {
  return {
    type: C.SET_HEADER,
    payload: headerName
  };
};
export const clearHeader = () => {
  return {
    type: C.CLEAR_HEADER
  };
};
export const login = (username, password) => {
  return axios.get(BASE_URL + "/login", {
    params: {
      username: username,
      password: password
    }
  });
};
export const setApikey = apiKey => dispatch => {
  const cookies = new Cookies();
  cookies.set("apiKey", apiKey, {
    path: "/",
    sameSite: "strict"
  });
  dispatch({
    type: C.SET_API_KEY,
    payload: apiKey
  });
};

export const logout = () => dispatch => {
  const cookies = new Cookies();
  cookies.remove("apiKey", { path: "/", sameSite: "strict" });
  dispatch({
    type: C.CLEAR_API_KEY,
    payload: null
  });
};
export const upDateAd = (name, url, type, file) => (dispatch, getState) => {
  const data = new FormData();
  data.append("file", file);
  data.append("name", name);
  data.append("url", url);
  data.append("type", type);
  axios.post(BASE_URL + "/ads", data).then(response => {
    dispatch({
      type: C.SET_ADS,
      payload: response.data
    });
  });
};
export const addCategory = (name, parent_id, description, file) => (
  dispatch,
  getState
) => {
  const data = new FormData();
  data.append("file", file);
  data.append("name", name);
  data.append("parent_id", parent_id);
  data.append("description", description);
  axios
    .post(BASE_URL + "/category", data, {
      headers: { Authorization: `Bearer ${getState().apiKey}` }
    })
    .then(response => {
      dispatch({
        type: C.ADD_CATEGORY,
        payload: response.data
      });
    });
};

export const updateCategory = (id, name, parent_id, description, file) => (
  dispatch,
  getState
) => {
  const data = new FormData();
  data.append("file", file);
  data.append("name", name);
  data.append("parent_id", parent_id);
  data.append("description", description);
  axios
    .post(BASE_URL + "/category/" + id, data, {
      headers: { Authorization: `Bearer ${getState().apiKey}` }
    })
    .then(response => {
      dispatch({
        type: C.UPDATE_CATEGORY,
        payload: response.data
      });
    });
};
// export const updateCategory = (id, name, description) => (
//   dispatch,
//   getState
// ) => {
//   const data = new FormData();
//   //data.append("file", file);
//   data.append("name", name);
//   data.append("id", id);
//   //data.append("parent_id", parent_id);
//   data.append("description", description);
//   axios
//     .put(
//       BASE_URL + "/category/" + id,
//       {
//         name: name,
//         description: description
//       },
//       {
//         headers: { Authorization: `Bearer ${getState().apiKey}` }
//       }
//     )
//     .then(response => {
//       console.log(response.data);
//       dispatch({
//         type: C.UPDATE_CATEGORY,
//         payload: response.data
//       });
//     });
// };
export const removeCategory = id => (dispatch, getState) => {
  axios
    .delete(BASE_URL + "/category/" + id, {
      headers: { Authorization: `Bearer ${getState().apiKey}` }
    })
    .then(response => {
      if (response.status === 204) {
        dispatch({
          type: C.REMOVE_CATEGORY,
          payload: id
        });
      }
    })
    .catch(error => {
      dispatch(addError(error.message));
    });
};
export const bulkUploadResource = (resources, category_id) => dispatch => {
  axios
    .post(BASE_URL + "/resources_bulk", {
      category_id: category_id,
      resources: resources
    })
    .then(response => {
      dispatch({
        type: C.ADD_DOMAIN_RESOURCE,
        payload: response.data
      });
    })
    .catch(error => {
      //console.log(error.message);
      dispatch(addError(error.message));
    });
};

export const addDomainResource = (
  id,
  name,
  url,
  description,
  category_id
) => dispatch => {
  axios
    .post(BASE_URL + "/resources", {
      name: name,
      url: url,
      category_id: category_id,
      description: description
    })
    .then(response => {
      dispatch({
        type: C.ADD_DOMAIN_RESOURCE,
        payload: response.data
      });
    })
    .catch(error => {
      dispatch(addError(error.message));
    });
};
export const removeDomainResource = id => dispatch => {
  axios
    .delete(BASE_URL + "/resources/" + id)
    .then(response => {
      if (response.status === 204) {
        dispatch({
          type: C.REMOVE_DOMAIN_RESOURCE,
          payload: id
        });
      }
    })
    .catch(error => {
      dispatch(addError(error.message));
    });
};
