import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { upDateAd } from "store/Actions.js";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import CardImages from "components/Card/CardImages.jsx";
//import axios from "axios";

//const BASE_URL = "http://localhost/api";
class Ads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.handleHeaderSave = this.handleHeaderSave.bind(this);
    this.handleFooterSave = this.handleFooterSave.bind(this);
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  handleHeaderSave = (url, file) => {
    this.props.upDateAd("name", url, 1, file);
  };
  handleFooterSave = (url, file) => {
    this.props.upDateAd("name", url, 2, file);
  };
  render() {
    const { ads } = this.props;
    return (
      <div>
        <h3>Header Ad</h3>
        <br />
        <CardImages handleAdSave={this.handleHeaderSave} ad={ads[0]} />
        <h3>Footer Ad</h3>
        <CardImages handleAdSave={this.handleFooterSave} ad={ads[1]} />
      </div>
    );
  }
}

Ads.propTypes = {
  classes: PropTypes.object.isRequired,
  upDateAd: PropTypes.func,
  ads: PropTypes.array
};

const mapStateToProps = state => {
  return {
    ads: state.ads
  };
};

const mapDispatchToProps = () => dispatch => {
  return {
    upDateAd(name, url, type, file) {
      dispatch(upDateAd(name, url, type, file));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(Ads));

//export default withStyles(dashboardStyle)(Ads);
