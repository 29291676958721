import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  addCategory,
  removeCategory,
  setHeader,
  BASE_URL
} from "store/Actions.js";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
//import Icon from "@material-ui/core/Icon";
import BackArrow from "@material-ui/icons/ArrowBackIos";
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";
import Tooltip from "@material-ui/core/Tooltip";
// import InfoOutline from "@material-ui/icons/InfoOutline";
//import Warning from "@material-ui/icons/Warning";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import CategoryModal from "custom_components/CategoryModal.jsx";
//const BASE_URL = "http://localhost/api/";

const style = {
  iconPosition: {
    position: "absolute",
    zIndex: "1",
    width: "calc(100% - 30px)",
    left: "17px",
    right: "17px",
    textAlign: "center",
    lineHeight: "22px"
  },
  footer: {
    height: "22px"
  },
  ...dashboardStyle,
  ...sweetAlertStyle
};
class AdminCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      show: false,
      value: 0,
      categoryModal: false,
      updateCategory: false,
      category: {}
    };
    this.inputConfirmAlert = this.inputConfirmAlert.bind(this);
    this.inputConfirmAlertNext = this.inputConfirmAlertNext.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  getParent = categoryID => {
    var isParent = false;
    this.props.allCategories.map(cat => {
      if (cat.parent_id === categoryID) {
        isParent = true;
      }
      return isParent;
    });
    return isParent;
  };
  handleCardClick = (categoryID, categoryName) => {
    this.props.setHeader(categoryName);
    //this.props.history.push("/admin/category/" + categoryID);
    this.openInNewTab("/app/admin/category/" + categoryID);
  };
  handleAddSubCategory = (parentID, categoryName) => {
    this.props.setHeader(categoryName);
    //this.props.history.push("/admin/subcategory/" + parentID);
    this.openInNewTab("/app/admin/subcategory/" + parentID);
  };

  openInNewTab = url => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  handleAddCategory = () => {
    this.inputAlert.bind(this);
  };

  handleRemoveCategory = (id, name) => {
    //this.props.removeCategory(id);
    this.warningWithConfirmMessage(id, name);
  };

  inputAlert() {
    this.setState({
      alert: (
        <SweetAlert
          input
          showCancel
          validationMsg="Enter Category Name"
          style={{ display: "block", marginTop: "-100px" }}
          title="Enter Name of Category"
          onConfirm={e => this.inputConfirmAlert(e)}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.info
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
        />
      )
    });
  }
  inputConfirmAlert(e) {
    this.setState({ alert: e }, () => {
      this.inputConfirmAlertNext();
    });
  }
  inputConfirmAlertNext() {
    this.hideAlert();
    const inputValue = this.state.alert;
    this.props.addCategory(inputValue, this.props.parent_id);
  }
  warningWithConfirmMessage(id, name) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Delete Category ?"
          onConfirm={() => this.deleteCategory(id)}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
        >
          {name} and it resources will be deleted!
        </SweetAlert>
      )
    });
  }

  deleteCategory(id) {
    this.props.removeCategory(id);
    this.successDelete();
  }
  successDelete() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          Campaign has been delted!
        </SweetAlert>
      )
    });
  }
  hideAlert() {
    this.setState({
      alert: null
    });
  }

  closeModal() {
    this.setState({
      categoryModal: false
    });
  }
  render() {
    const { classes, categories, parent_id } = this.props;
    return (
      <div>
        {this.state.categoryModal ? (
          <CategoryModal
            modalOpen={this.state.categoryModal}
            parent_id={this.props.parent_id}
            update={this.state.updateCategory}
            category={this.state.category}
            closeModal={this.closeModal}
            style={{ width: "1000px" }}
          />
        ) : null}
        {parent_id > 0 ? (
          <Button
            color="success"
            onClick={() => this.props.history.push("/admin/dashboard")}
          >
            <BackArrow /> Back
          </Button>
        ) : null}
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card
              raised
              onClick={() =>
                this.setState({ categoryModal: true, updateCategory: false })
              }
              style={{ cursor: "pointer" }}
            >
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Add />
                </CardIcon>
                <h3 className={classes.cardTitleCategory}> Add Category </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats} />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          {this.state.alert}
          {categories.map((category, index) => {
            return (
              <GridItem xs={12} sm={6} md={6} lg={3} key={index}>
                <Card raised>
                <Link
                    to={
                      this.getParent(category.id)
                        ? "/admin/subcategory/" + category.id
                        : "/admin/category/" + category.id
                    }
                  >
                    <CardHeader
                      color="warning"
                      stats
                      icon
                      // onClick={() =>
                      //   this.handleCardClick(category.id, category.name)
                      // }
                      style={{ cursor: "pointer" }}
                    >
                      <CardIcon color="warning">
                        {category.image_path === "" ? (
                          <Add />
                        ) : (
                          <img
                            style={{ maxWidth: "48px", maxHeight: "48px" }}
                            width={"1120px"}
                            height="90px"
                            className={classes.cardImgTop}
                            src={BASE_URL + "/" + category.image_path}
                            alt="..."
                          />
                        )}
                      </CardIcon>
                      {/* <p className={classes.cardCategory}> {category.name} </p> */}
                      <h3 className={classes.cardTitleCategory}>
                        {category.name}
                      </h3>
                    </CardHeader>
                  </Link>
                  <CardFooter stats className={classes.footer}>
                    <div className={classes.iconPosition}>
                      <Tooltip
                        id="tooltip-top"
                        title="Remove"
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          color="danger"
                          simple
                          justIcon
                          onClick={() =>
                            this.handleRemoveCategory(
                              category.id,
                              category.name
                            )
                          }
                        >
                          <Close className={classes.underChartIcons} />
                        </Button>
                      </Tooltip>
                      <Tooltip
                        id="tooltip-top"
                        title="Add Sub Category"
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          color="success"
                          simple
                          justIcon
                          onClick={() =>
                            this.handleAddSubCategory(
                              category.id,
                              category.name
                            )
                          }
                        >
                          <Add className={classes.underChartIcons} />
                        </Button>
                      </Tooltip>
                      <Tooltip
                        id="tooltip-top"
                        title="Edit Category"
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          color="warning"
                          simple
                          justIcon
                          onClick={() =>
                            this.setState({
                              categoryModal: true,
                              updateCategory: true,
                              category: category
                            })
                          }
                        >
                          <Edit className={classes.underChartIcons} />
                        </Button>
                      </Tooltip>
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            );
          })}
        </GridContainer>
      </div>
    );
  }
}

AdminCategory.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  categories: PropTypes.array,
  allCategories: PropTypes.array,
  addCategory: PropTypes.func,
  removeCategory: PropTypes.func,
  setHeader: PropTypes.func,
  parent_id: PropTypes.number
};

const mapStateToProps = (state, props) => {
  var id = props.match.params.id;
  if (id === undefined) {
    id = 0;
  }
  var categories = state.category.filter(
    c => parseInt(c.parent_id, 10) === parseInt(id, 10)
  );
  return {
    categories: categories,
    allCategories: state.category,
    parent_id: parseInt(id, 10)
  };
};

const mapDispatchToProps = () => dispatch => {
  return {
    addCategory(categoryName, parent_id) {
      dispatch(addCategory(categoryName, parent_id));
    },
    removeCategory(id) {
      dispatch(removeCategory(id));
    },
    setHeader(headerName) {
      dispatch(setHeader(headerName));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(AdminCategory));
//export default withStyles(dashboardStyle)(CategoryDashboard);
