import C from "./constants";
import { combineReducers } from "redux";

const header_text = (state = "", action) => {
  switch (action.type) {
    case C.SET_HEADER:
      return action.payload;
    case C.CLEAR_HEADER:
      return "";
    default:
      return state;
  }
};
export const ads = (state = [], action) => {
  switch (action.type) {
    case C.ADD_AD:
      return [...state, action.payload];
    case C.REMOVE_AD:
      return state.filter(ad => ad.id !== action.payload);
    case C.SET_ADS:
      return action.payload;
    default:
      return state;
  }
};
export const category = (state = [], action) => {
  switch (action.type) {
    case C.ADD_CATEGORY:
      return [...state, action.payload];
    case C.SET_CATEGORY:
      return action.payload;
    case C.UPDATE_CATEGORY:
      return state.map(category => {
        return category.id !== action.payload.id ? category : action.payload;
      });
    case C.REMOVE_CATEGORY:
      return state.filter(category => category.id !== action.payload);
    default:
      return state;
  }
};

export const domain_resourses = (state = null, action) => {
  switch (action.type) {
    case C.ADD_DOMAIN_RESOURCE:
      return [...state, ...action.payload];
    case C.SET_DOMAIN_RESOURCE:
      return action.payload;
    case C.MERGE_DOMAIN_RESOURCE:
      return [...state, ...action.payload];
    case C.REMOVE_DOMAIN_RESOURCE:
      return state.filter(resource => resource.id !== action.payload);
    default:
      return state;
  }
};

export const user = (state = null, action) => {
  switch (action.type) {
    case C.LOGIN_USER:
      return action.payload;
    case C.LOGOUT_USER:
      return null;
    default:
      return state;
  }
};
export const apiKey = (state = null, action) => {
  switch (action.type) {
    case C.SET_API_KEY:
      return action.payload;
    case C.CLEAR_API_KEY:
      return null;
    default:
      return state;
  }
};

export const errors = (state = [], action) => {
  switch (action.type) {
    case C.ADD_ERROR:
      return [...state, action.payload];
    case C.CLEAR_ERROR:
      return state.filter((message, index) => index !== action.payload);
    default:
      return state;
  }
};
export const fetching = (state = false, action) => {
  switch (action.type) {
    case C.FETCH_LEADS:
      return true;
    case C.CANCEL_FETCHING:
      return false;
    case C.CHANGE_SUGGESTIONS:
      return false;
    default:
      return state;
  }
};

const appReducer = combineReducers({
  header_text,
  apiKey,
  category,
  domain_resourses,
  ads,
  errors
});
// const rootReducer = (state, action) => {
//   if (action.type === C.USER_LOGOUT) {
//     state = undefined;
//   }
//   return appReducer(state, action);
// };

export default appReducer;
//export default rootReducer;
