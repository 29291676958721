import LockScreenPage from "views/Pages/LockScreenPage.jsx";
import RegisterPage from "views/Pages/RegisterPage.jsx";
import LoginPage from "views/Pages/LoginPage.jsx";
var authRoutes = [
  {
    path: "/login-page",
    name: "Login",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: LoginPage,
    layout: "/auth"
  },
  {
    path: "/register-page",
    name: "Register Page",
    rtlName: "تسجيل",
    mini: "R",
    rtlMini: "صع",
    component: RegisterPage,
    layout: "/auth"
  },
  {
    path: "/lock-screen-page",
    name: "Lock Screen Page",
    rtlName: "اقفل الشاشة",
    mini: "LS",
    rtlMini: "هذاع",
    component: LockScreenPage,
    layout: "/auth"
  },
  {
    redirect: true,
    path: "/auth",
    pathTo: "/auth/dashboard",
    name: "Dashboard"
  }
];
export default authRoutes;
