import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Papa from "papaparse";
import XLSX from "xlsx";
import Add from "@material-ui/icons/Add";
import {
  addDomainResource,
  bulkUploadResource,
  removeDomainResource,
  BASE_URL
} from "store/Actions.js";
// react component for creating dynamic tables
import ReactTable from "react-table";
//import CSVReader from "react-csv-reader";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/icons
//import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Back from "@material-ui/icons/ArrowBackIos";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const styles = {
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  description: {
    ...cardTitle,
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  cardLink: {
    color: "black",
    "&:hover": {
      color: "black"
    }
  }
};

class ResourceTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      url: "",
      description: "",
      alert: null,
      show: false
    };
    this.successUpload = this.successUpload.bind(this);
    this.cancelUpload = this.cancelUpload.bind(this);
  }

  change(event, type) {
    this.setState({ [type]: event.target.value });
  }
  addResource() {
    this.props.addDomainResource(
      4,
      this.state.name,
      this.state.url,
      this.state.description,
      this.props.category.id
    );
    this.setState({
      name: "",
      url: "",
      description: ""
    });
  }

  handleFileSelect = e => {
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      var results = Papa.parse(reader.result);
      var data = results.data;
      // remove first row (header)
      data.shift();
      //this.props.bulkUploadResource(data, this.props.category.id);
      this.warningWithConfirmAndCancelMessage(
        file.name,
        data,
        this.props.category.id
      );
    };
    reader.readAsText(file);
    e.target.value = null;
  };

  warningWithConfirmAndCancelMessage(name, data, categoryId) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => this.successUpload(name, data, categoryId)}
          onCancel={() => this.cancelUpload()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes, upload it!"
          cancelBtnText="Cancel"
          showCancel
        >
          {name} will be uploaded
        </SweetAlert>
      )
    });
  }

  successUpload(name, data, categoryId) {
    this.props.bulkUploadResource(data, categoryId);
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Uploaded!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          {name} is uploaded!!!
        </SweetAlert>
      )
    });
  }
  cancelUpload() {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Cancelled"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          Upload is Cancelled
        </SweetAlert>
      )
    });
  }
  hideAlert() {
    this.setState({
      alert: null
    });
  }
  // Handling XLSX file
  handleXlsxFile = e => {
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      const bstr = reader.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      /* Update state */
      console.log("Data>>>" + data);
    };
    reader.readAsBinaryString(file);
  };

  getData() {
    return this.props.resources.map((prop, key) => {
      return {
        id: key,
        name: prop.name,
        description: prop.description,
        url: (
          <div>
            <a
              href={prop.url}
              target="_blank"
              rel="noopener noreferrer"
              className={this.props.classes.cardLink}
            >
              {prop.url}
            </a>
          </div>
        ),
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                window.open(prop.url, "_blank");
              }}
              color="warning"
              className="edit"
            >
              <Dvr />
            </Button>{" "}
            {/* use this button to remove the data row */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                this.props.removeDomainResource(prop.id);
              }}
              color="danger"
              className="remove"
            >
              <Close />
            </Button>{" "}
          </div>
        )
      };
    });
  }
  render() {
    const { classes, category } = this.props;
    return (
      <GridContainer>
        {this.state.alert}
        <GridItem>
          <Button
            color="success"
            onClick={() => this.props.history.goBack()}
          >
            <Back />
            Back
          </Button>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <GridContainer alignItems="baseline">
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  <CustomInput
                    labelText="Name"
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "name",
                      value: this.state.name,
                      onChange: event => this.change(event, "name")
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  <CustomInput
                    labelText="Url"
                    id="url"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "url",
                      value: this.state.url,
                      onChange: event => this.change(event, "url"),
                      onKeyPress: event => {
                        if (event.key === "Enter") this.addResource();
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  <CustomInput
                    labelText="Description"
                    id="description"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "url",
                      value: this.state.description,
                      onChange: event => this.change(event, "description"),
                      onKeyPress: event => {
                        if (event.key === "Enter") this.addResource();
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={2} lg={1}>
                  <Button color="success" onClick={() => this.addResource()}>
                    Submit
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={6} md={2} lg={1}>
                  <div className="fileinput">
                    <Button
                      color="success"
                      onClick={() => this.refs.fileInput.click()}
                    >
                      Select CSV
                    </Button>
                    <input
                      type="file"
                      onChange={this.handleFileSelect}
                      ref="fileInput"
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="success">
                {category.image_path === "" ? (
                  <Add />
                ) : (
                  <img
                    style={{ maxWidth: "48px", maxHeight: "48px" }}
                    width={"1120px"}
                    height="90px"
                    className={classes.cardImgTop}
                    src={BASE_URL + "/" + category.image_path}
                    alt="..."
                  />
                )}
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{category.name}</h4>
              <h4 className={classes.description}>{category.description}</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={this.getData()}
                //filterable
                columns={[
                  {
                    Header: "Name",
                    accessor: "name",
                    width: 250
                  },
                  {
                    Header: "URL",
                    accessor: "url",
                    width: 300
                  },
                  {
                    Header: "Description",
                    accessor: "description"
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    width: 100
                  }
                ]}
                defaultPageSize={100}
                minRows={1}
                showPaginationBottom
                //showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

ResourceTable.propTypes = {
  classes: PropTypes.object.isRequired,
  resources: PropTypes.array,
  category: PropTypes.object,
  addDomainResource: PropTypes.func,
  bulkUploadResource: PropTypes.func,
  removeDomainResource: PropTypes.func,
  history: PropTypes.object.isRequired
};
const mapStateToProps = (state, props) => {
  var id = props.match.params.id;
  var resources = state.domain_resourses.filter(
    r => r.category_id === parseInt(id, 10)
  );
  var categories = state.category.filter(c => c.id === parseInt(id, 10));
  var category = {};
  if (categories.length > 0) {
    category = categories[0];
  }
  return {
    resources: resources,
    category: category
  };
};

const mapDispatchToProps = () => dispatch => {
  return {
    addDomainResource(id, name, url, description, category_id) {
      dispatch(addDomainResource(id, name, url, description, category_id));
    },
    bulkUploadResource(resources, category_id) {
      dispatch(bulkUploadResource(resources, category_id));
    },
    removeDomainResource(id) {
      dispatch(removeDomainResource(id));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ResourceTable));
//export default withStyles(styles)(ReactTables);
