import React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import { getInitData } from "store/Actions.js";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "components/Header/Header.jsx";
//import HeaderLinks from "components/Header/UserHeaderLinks.jsx";
// core components
//import UserNavbar from "components/Navbars/UserNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
//import Sidebar from "components/Sidebar/Sidebar.jsx";
import routes from "routes.js";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";

import image from "assets/img/sidebar-2.jpg";
//import logo from "assets/img/logo-white.svg";
import backgroundImage from "assets/img/background.jpg";
import headerName from "assets/img/headerName.png";

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: true,
      image: image,
      color: "blue",
      bgColor: "black",
      hasImage: true,
      fixedClasses: "dropdown"
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", this.resizeFunction);
    this.props.getInitData();
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleBgColorClick = bgColor => {
    this.setState({ bgColor: bgColor });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/admin/full-screen-maps";
  }
  getActiveRoute = routes => {
    let activeRoute = this.props.headerText;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
      if (prop.layout === "") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  showAds() {
    /* if (window.location.pathname.indexOf("/dashboard") !== -1) {
      return false;
    } else {
      return true;
    } */
    return true;
  }
  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    return (
      <div
        className={classes.wrapper}
        style={{ backgroundImage: "url(" + backgroundImage + ")" }}
      >
        <Header
          brand="Rebrandable Content"
          path="/"
          headerImage={headerName}
          // rightLinks={<HeaderLinks />}
          fixed
          //color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        {/* <SectionNavbars /> */}
        {/* <Sidebar
          routes={routes}
          logoText={"Gorialogics"}
          logo={logo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          bgColor={this.state.bgColor}
          miniActive={this.state.miniActive}
          {...rest}
        /> */}
        {/* <a
          target="_blank"
          rel="noopener noreferrer"
          href={this.props.ads[0].url}
        >
          <img
            style={{
              maxWidth: "1120px",
              maxHeight: "90",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto"
            }}
            width={"1120px"}
            height="90px"
            src={"https://rebrandablecontent.com/api/" + this.props.ads[0].path}
            alt="..."
          />
        </a> */}

        <div className={mainPanel} ref="mainPanel">
          {/* <UserNavbar
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            brandText={this.getActiveRoute(routes)}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          /> */}
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>{this.getRoutes(routes)}</Switch>
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <Switch>{this.getRoutes(routes)}</Switch>
            </div>
          )}
          {this.showAds() ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={this.props.ads[1].url}
            >
              <img
                style={{
                  maxWidth: "1120px",
                  maxHeight: "90",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
                width={"1120px"}
                height="90px"
                src={
                  "https://rebrandablecontent.com/api/" + this.props.ads[1].path
                }
                alt="..."
              />
            </a>
          ) : null}
          <Footer fluid />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  getInitData: PropTypes.func,
  ads: PropTypes.array,
  headerText: PropTypes.string
};
const mapStateToProps = state => {
  return {
    categories: state.category,
    ads: state.ads,
    headerText: state.header_text
  };
};

const mapDispatchToProps = () => dispatch => {
  return {
    getInitData() {
      dispatch(getInitData());
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(appStyle)(Dashboard));
//export default withStyles(appStyle)(Dashboard);
