import Dashboard from "views/Dashboard/CategoryDashboard.jsx";
//import Resource from "views/Dashboard/Resource.jsx";
import ResourceTable from "views/Tables/ResourceTable.jsx";
import DashboardIcon from "@material-ui/icons/Dashboard";
var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: ""
  },
  {
    secure: false,
    hidden: true,
    path: "/category/:id",
    name: "Resource",
    icon: DashboardIcon,
    component: ResourceTable,
    layout: ""
  },
  {
    secure: true,
    hidden: true,
    path: "/subcategory/:id",
    name: "Resource",
    icon: DashboardIcon,
    component: Dashboard,
    layout: ""
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/dashboard",
    name: "Dashboard"
  }
];
export default dashRoutes;
